/* div {
  color: #444;
} */

.center-container {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100vw;
  /* height: 50vh; */
  flex-direction: column;
}

.authentication-input {
  height: 70px;
  width: 220px;
  font-size: 40px;
  margin-bottom: 20px;
}

.authentication-button {
  margin-top: 20px;
  width: 225px;
  height: 50px;
  background-color: white;
  border-color: #444;
  border-width: 1px;
  font-size: 25px;
}

.form-container {
  display: flex;
  flex-direction: column;
}
